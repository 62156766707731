



































































































































import { Component, Vue } from 'vue-property-decorator'
import SidePanel from 'studio-shared/vue/components/SidePanel.vue'
import AppointmentInfoComponent from './schedule/components/AppointmentInfoComponent.vue'

import { sharedInstance as loading } from '@/signals/FullScreenLoadingSignal'

import pluralize from 'pluralize'
import moment from 'moment'

import map from 'lodash/map'
import range from 'lodash/range'
import clone from 'lodash/clone'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import concat from 'lodash/concat'

import loader from '@/dataLoader'

const AppointmentGenerator = require('studio-shared/appointments')

const defaultAppointmentMeta = () => {
	return {
		date: null,
		idx: -1
	}
}

@Component({
	components: {
		SidePanel,
		AppointmentInfoComponent
	}
})
export default class Dashboard extends Vue
{
	private startingDate: Date | moment.Moment = moment().toDate()
	private period: any = {
		options: [
			{
				value: 0,
				name: 'Day'
			},
			{
				value: 1,
				name: 'Week'
			}
		],
		selected: 0
	}

	private showAppointmentInfo = false
	private selectedAppointmentMeta: any = defaultAppointmentMeta()

	private showOnlyMine = true

	get isMobile()
	{
		return window.innerWidth <= 768
	}

	get currentDate()
	{
		if (this.$route.params.currentDate)
		{
			return moment(this.$route.params.currentDate).toDate()
		}

		return this.startingDate
	}

	get currentTeacher()
	{
		return this.$store.getters.currentTeacher
	}

	get selectedAppointment()
	{
		if (!this.selectedAppointmentMeta.date)
		{
			return null
		}

		const date = this.monthDateYearShortFormat(moment(this.selectedAppointmentMeta.date).startOf('day'))
		const list = this.appointmentsForPeriodDates[date]
		const idx = findIndex(list, (item: any) => item.id === this.selectedAppointmentMeta.id)

		if (idx < 0)
		{
			return null
		}

		return list[idx]
	}

	get appointmentGenerator()
	{
		return new AppointmentGenerator({
			getClass: (uid: string) => this.$store.getters.class(uid),
			missedClassesForDate: (date: Date) => this.$store.getters['missedClassesByDate/forDate'](date),
			makeupsForDate: (date: Date) => this.$store.getters['makeupsByDate/forDate'](date),
			freeClassesForDate: (date: Date) => this.$store.getters['freeClassesByDate/forDate'](date),

			dancersForClass: (uid: string) => this.$store.getters.dancersForClass(uid),
			joinsForClass: (uid: string) => this.$store.getters['eventsByClass/joinsForClass'](uid),
			leavesForClass: (uid: string) => this.$store.getters['eventsByClass/leavesForClass'](uid)
		})
	}

	get selectedPeriod()
	{
		return this.period.options[this.period.selected]
	}

	get periodDates()
	{
		const period = this.selectedPeriod
		switch (period.value)
		{
			default:
			case 0:
			{
				const date = moment(this.currentDate).startOf('day')

				loader.fetchAndListenForMissedClassesByDate(date.toDate())
				loader.fetchAndListenForMakeupsByDate(date.toDate())
				loader.fetchAndListenForFreeClassesByDate(date.toDate())

				return [date]
			}

			case 1:
			{
				const weekStart = moment(this.currentDate).startOf('day')
				return map(range(7), i => {
					const date = moment(weekStart).startOf('day').add(i, 'day')

					loader.fetchAndListenForMissedClassesByDate(date.toDate())
					loader.fetchAndListenForMakeupsByDate(date.toDate())
					loader.fetchAndListenForFreeClassesByDate(date.toDate())

					return date
				})
			}
		}
	}

	get appointmentsForPeriodDates()
	{
		const ret: any = {}

		forEach(this.periodDates, date => {
			ret[this.monthDateYearShortFormat(date)] = this.appointmentsForDate(date)
		})

		return ret
	}

	get columnSizes()
	{
		const period = this.selectedPeriod
		return {
			'is-half': period.value === 0,
			'is-one-third': period.value > 0
		}
	}

	get closureDates()
	{
		const now = moment()
		var closures = this.$store.getters['closures/closures'](now.format('YYYY'))
		if (now.month() >= 10)
		{
			const nextYear = now.year() + 1
			closures = concat(closures, this.$store.getters['closures/closures'](nextYear.toString()))
		}

		const lookup: any = {}

		forEach(closures, d => {
			lookup[d.format('YYYY-MM-DD')] = true
		})
		
		return lookup
	}
	
	mounted()
	{
		// show day as default on mobile and week on desktop
		if (this.isMobile)
		{
			this.period.selected = 0
		}
		else
		{
			this.period.selected = 1
		}

		// get the closure dates
		const now = moment()
		loader.fetchAndListenForClosures(now.year().toString())
		if (now.month() >= 10)
		{
			const nextYear = now.year() + 1
			loader.fetchAndListenForClosures(nextYear.toString())
		}
	}

	private handleToday(evt: MouseEvent)
	{
		evt.preventDefault()

		this.startingDate = moment().toDate()
		this.addStartingDateToRoute()
	}

	private handleNextPeriod(evt: MouseEvent)
	{
		evt.preventDefault()

		const period = this.period.selected === 0 ? 'day' : 'week'

		this.startingDate = moment(this.startingDate).add(1, period)
		this.addStartingDateToRoute()
	}

	private handlePrevPeriod(evt: MouseEvent)
	{
		evt.preventDefault()

		const period = this.period.selected === 0 ? 'day' : 'week'

		this.startingDate = moment(this.startingDate).subtract(1, period)
		this.addStartingDateToRoute()
	}

	private addStartingDateToRoute()
	{
		this.$router.replace('/dashboard/' + moment(this.startingDate).format('YYYY-MM-DD'))
	}

	private appointmentsForDate(date: moment.Moment)
	{
		const classesForDate = this.$store.getters.classesForDayOfWeek(date.format('dddd'))
		const activeClassesForDate = filter(classesForDate, c => {
			const active = c.status === 'Active'
			var discontinuedButActive = false
			if (c.endDate)
			{
				discontinuedButActive = c.status === 'Discontinued' && moment(c.endDate).isSameOrAfter(date, 'day')
			}

			const hasStarted = moment(c.startDate).isSameOrBefore(date, 'day')
			const isCurrentTeacher = c.instructor === this.currentTeacher.uid

			const subs: ISubstitute[] = this.$store.getters['substitutes/forDate'](date)
			const activeSub = subs.find(sub => sub.classId === c.uid)

			let isCurrentSub = false
			if (activeSub)
			{
				isCurrentSub = activeSub.instructor === this.currentTeacher.uid	
			}

			const keep = (active || discontinuedButActive) && hasStarted

			if (this.showOnlyMine)
			{
				return (isCurrentTeacher || isCurrentSub) && keep
			}

			return keep
		})

		return sortBy(map(activeClassesForDate, (classData, idx) => {
			loader.fetchAndListenForDancerEventsByClass(classData.uid)

			const appt = this.appointmentGenerator.generateClassDataForDate(classData, date.toDate())

			const startTime = this.to24Hour(classData.startTime)
			appt.date = moment(appt.date).hour(startTime.hour).minute(startTime.minute).toDate()
			const cd = clone(classData)
			cd.classType = this.$store.getters.classType(cd.classType)
			cd.classLevel = this.$store.getters.classLevel(cd.classLevel)
			cd.instructor = this.$store.getters.teacher(cd.instructor)
			cd.studio = this.$store.getters.studio(cd.studio)
			appt.classData = cd
			appt.id = idx

			const subs: ISubstitute[] = this.$store.getters['substitutes/forDate'](date)
			const activeSub = subs.find(sub => sub.classId === classData.uid)

			if (activeSub)
			{
				appt.substitute = {
					uid: activeSub.uid,
					instructor: this.$store.getters.teacher(activeSub.instructor)
				}
			}
			
			return appt
		}), 'date')
	}

	private handleSelectAppointment(appt: any)
	{
		if (this.isMobile)
		{
			this.$router.push(`/appointments/${appt.classData.uid}/on/${moment(appt.date).format('YYYY-MM-DD')}`)
			return
		}

		this.showAppointmentInfo = true
		this.selectedAppointmentMeta = {
			date: appt.date,
			id: appt.id
		}
	}

	private handleCloseAppointmentInfo()
	{
		this.showAppointmentInfo = false
		this.selectedAppointmentMeta = defaultAppointmentMeta()
	}

	private isSelectedAppointment(appt: any)
	{
		if (!this.selectedAppointment)
		{
			return false
		}

		const sameClass = appt.classData.uid == this.selectedAppointment.classData.uid
		const sameDate = moment(appt.date).isSame(moment(this.selectedAppointment.date))

		return sameClass && sameDate
	}

	private isSubbed(appt: any)
	{
		if (!appt.substitute)
		{
			return false
		}

		return appt.substitute.instructor.uid !== this.currentTeacher.uid
	}

	private isSubbing(appt: any)
	{
		if (!appt.substitute)
		{
			return false
		}

		return appt.substitute.instructor.uid === this.currentTeacher.uid
	}

	private to24Hour(startTime: string)
	{
		const parts = startTime.split(' ')
		const isPm = parts[1] === 'pm'

		const timeParts = parts[0].split(':')
		var hour = parseInt(timeParts[0])
		if (isPm && hour !== 12)
		{
			hour += 12
		}

		const minutes = parseInt(timeParts[1])

		return {
			hour: hour,
			minute: minutes
		}
	}

	private classTitle(classData: any)
	{
		const type = classData.classType.name
		const level = type === 'Stretch' ? '' : classData.classLevel.name
		return `${type} ${level} @ ${classData.startTime.replace(' ', '')}`
	}

	private colorForStudio(name: string)
	{
		return {
			'is-info': name === '1060 Bannock',
			'is-primary': name === '1111 Broadway'
		}
	}

	private monthDateYearShortFormat(date: Date | moment.Moment)
	{
		return moment(date).format('MMM Do YYYY')
	}

	private isStudioClosed(date: Date)
	{
		return this.closureDates[moment(date).format('YYYY-MM-DD')]
	}

	private pluralize(str: string, count: number)
	{
		return pluralize(str, count)
	}

	private setLoading(val: boolean)
	{
		loading.fire(val)
	}
}
