import { Subject } from 'rxjs'

export default class Signal<Type>
{
	private subject: Subject<Type> = new Subject()

	asObservable()
	{
		return this.subject.asObservable()
	}

	fire(val: Type)
	{
		this.subject.next(val)
	}
}
