import Signal from './Signal'

export default class FullScreenLoadingSignal extends Signal<boolean>
{
}

const sharedInstance = new FullScreenLoadingSignal()

export {
	sharedInstance
}
