var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "navbar",
      attrs: { role: "navigation", "aria-label": "main navigation" }
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "navbar-menu", attrs: { id: "navbarBasicExample" } },
        [
          _c("div", { staticClass: "navbar-start" }),
          _c("div", { staticClass: "navbar-end" }, [
            _c("div", { staticClass: "navbar-item" }, [
              _c("div", { staticClass: "buttons" }, [
                _c(
                  "a",
                  {
                    staticClass: "button is-info is-outlined",
                    on: { click: _vm.handleLogOut }
                  },
                  [_vm._v(" Log Out ")]
                )
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "navbar-brand" }, [
      _c("div", { staticClass: "navbar-item" }, [
        _c("img", {
          attrs: { src: require("../assets/logo.png"), height: "100" }
        }),
        _vm._v(" "),
        _c("strong", [_vm._v("Teachers")])
      ]),
      _c(
        "a",
        {
          staticClass: "navbar-burger burger",
          attrs: {
            role: "button",
            "aria-label": "menu",
            "aria-expanded": "false",
            "data-target": "navbarBasicExample"
          }
        },
        [
          _c("span", { attrs: { "aria-hidden": "true" } }),
          _c("span", { attrs: { "aria-hidden": "true" } }),
          _c("span", { attrs: { "aria-hidden": "true" } })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }