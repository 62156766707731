var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "section" }, [
        _c("div", { staticClass: "container" }, [
          _c("nav", { staticClass: "level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("div", { staticClass: "level-item" }, [
                _c("h1", { staticClass: "title" }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showOnlyMine && _vm.currentTeacher.name,
                          expression: "showOnlyMine && currentTeacher.name"
                        }
                      ]
                    },
                    [_vm._v(_vm._s(_vm.currentTeacher.name) + "'s ")]
                  ),
                  _vm._v("Schedule ")
                ])
              ]),
              _c("div", { staticClass: "level-item is-hidden-mobile" }, [
                _c("div", { staticClass: "field has-addons" }, [
                  _c(
                    "p",
                    { staticClass: "control" },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: { placeholder: "Select a period" },
                          model: {
                            value: _vm.period.selected,
                            callback: function($$v) {
                              _vm.$set(_vm.period, "selected", $$v)
                            },
                            expression: "period.selected"
                          }
                        },
                        _vm._l(_vm.period.options, function(option) {
                          return _c(
                            "option",
                            {
                              key: option.value,
                              domProps: { value: option.value }
                            },
                            [_vm._v(" " + _vm._s(option.name) + " ")]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _c("p", { staticClass: "control" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        on: { click: _vm.handlePrevPeriod }
                      },
                      [_vm._m(0)]
                    )
                  ]),
                  _c("p", { staticClass: "control" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        on: { click: _vm.handleNextPeriod }
                      },
                      [_vm._m(1)]
                    )
                  ])
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "level-item is-hidden-desktop is-hidden-tablet"
                },
                [
                  _c("div", { staticClass: "field has-addons" }, [
                    _c("p", { staticClass: "control" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-large",
                          on: { click: _vm.handleToday }
                        },
                        [_c("span", [_vm._v("Today")])]
                      )
                    ]),
                    _c("p", { staticClass: "control" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-large",
                          on: { click: _vm.handlePrevPeriod }
                        },
                        [_vm._m(2)]
                      )
                    ]),
                    _c("p", { staticClass: "control" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-large",
                          on: { click: _vm.handleNextPeriod }
                        },
                        [_vm._m(3)]
                      )
                    ])
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "level-right" }, [
              _c("div", { staticClass: "level-item" }, [
                _c(
                  "div",
                  {
                    staticClass: "field",
                    staticStyle: {
                      "margin-left": "10px",
                      "padding-bottom": "10px"
                    }
                  },
                  [
                    _c(
                      "b-switch",
                      {
                        model: {
                          value: _vm.showOnlyMine,
                          callback: function($$v) {
                            _vm.showOnlyMine = $$v
                          },
                          expression: "showOnlyMine"
                        }
                      },
                      [_vm._v(" Only My Classes ")]
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("hr"),
          _c(
            "div",
            { staticClass: "columns", staticStyle: { "overflow-x": "auto" } },
            [
              _vm._l(_vm.periodDates, function(date) {
                return [
                  _c(
                    "div",
                    {
                      key: date.toDate().toString(),
                      staticClass: "column",
                      class: _vm.columnSizes
                    },
                    [
                      _c("h1", { staticClass: "title is-5" }, [
                        _vm._v(_vm._s(date.format("dddd, MMM Do YYYY")))
                      ]),
                      _vm.isStudioClosed(date)
                        ? _c("div", { staticClass: "content has-text-grey" }, [
                            _vm._v(" Studio is closed. ")
                          ])
                        : _vm.appointmentsForPeriodDates[
                            _vm.monthDateYearShortFormat(date)
                          ].length === 0
                        ? _c("div", { staticClass: "content has-text-grey" }, [
                            _vm._v(" No classes scheduled. ")
                          ])
                        : _vm._l(
                            _vm.appointmentsForPeriodDates[
                              _vm.monthDateYearShortFormat(date)
                            ],
                            function(appt) {
                              return _c(
                                "div",
                                {
                                  key: appt.classData.uid,
                                  staticClass: "box clickable",
                                  class: {
                                    "selected-background": _vm.isSelectedAppointment(
                                      appt
                                    ),
                                    "has-background-grey-lighter": _vm.isSubbed(
                                      appt
                                    ),
                                    "has-background-subbing": _vm.isSubbing(
                                      appt
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleSelectAppointment(appt)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.classTitle(appt.classData))
                                  ),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "tag",
                                      class: _vm.colorForStudio(
                                        appt.classData.studio.name
                                      )
                                    },
                                    [
                                      _vm._v(
                                        " at " +
                                          _vm._s(appt.classData.studio.name) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm._v(" with "),
                                  appt.substitute
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "strike",
                                            { staticClass: "has-text-grey" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  appt.classData.instructor.name
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("u", [
                                            _vm._v(
                                              _vm._s(
                                                appt.substitute.instructor.name
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _c("u", [
                                          _vm._v(
                                            _vm._s(
                                              appt.classData.instructor.name
                                            )
                                          )
                                        ])
                                      ]),
                                  _c("br"),
                                  _vm._v(
                                    " Expecting " +
                                      _vm._s(appt.expectedDancers) +
                                      " " +
                                      _vm._s(
                                        _vm.pluralize(
                                          "dancer",
                                          appt.expectedDancers
                                        )
                                      ) +
                                      " "
                                  ),
                                  appt.expectedMakeups > 0
                                    ? _c("span", [
                                        _c("i", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(appt.expectedMakeups) +
                                              " " +
                                              _vm._s(
                                                _vm.pluralize(
                                                  "makeup",
                                                  appt.expectedMakeups
                                                )
                                              ) +
                                              ")"
                                          )
                                        ])
                                      ])
                                    : _vm._e()
                                ]
                              )
                            }
                          )
                    ],
                    2
                  )
                ]
              })
            ],
            2
          )
        ])
      ]),
      _c(
        "side-panel",
        {
          attrs: {
            isOpen: _vm.showAppointmentInfo && _vm.selectedAppointment !== null
          },
          on: { closeMenu: _vm.handleCloseAppointmentInfo }
        },
        [
          _vm.selectedAppointment
            ? _c(
                "div",
                { staticStyle: { padding: "10px", width: "400px" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "button is-outlined",
                      on: {
                        click: function($event) {
                          _vm.showAppointmentInfo = false
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "icon" }, [
                        _c("i", { staticClass: "fas fa-chevron-left" })
                      ]),
                      _c("span", [_vm._v("Back")])
                    ]
                  ),
                  _c("br"),
                  _c("br"),
                  _c("appointment-info-component", {
                    attrs: {
                      "class-uid": _vm.selectedAppointment.classData.uid,
                      "class-date": _vm.selectedAppointment.date
                    },
                    on: {
                      "dancer-miss-begin": function($event) {
                        return _vm.setLoading(true)
                      },
                      "dancer-miss-end": function($event) {
                        return _vm.setLoading(false)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-chevron-left" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-chevron-right" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-chevron-left" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-chevron-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }