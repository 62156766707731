<template>
<section class="section">
	<div class="container" style="margin-top: 50px; margin-bottom: 50px">
		<div class="container has-text-centered" style="width: 100%">
			<p class="title">
				<img src="../assets/logo.png" style="height: 75px; vertical-align: middle">&nbsp;
				Teachers
			</p>
		</div>
		<br>
		<div class="box">
			<section>
				
				<div class="notification is-danger" v-if="error.message">
					{{ error.message }}
				</div>

				<form @submit="handleLogin">
					<b-field label="Email"
						type=""
						message="">
						<b-input v-model="form.email"
							type="email"
							icon="envelope">
						</b-input>
					</b-field>
					
					<b-field label="Password">
						<b-input v-model="form.password"
							type="password"
							icon="key"
							password-reveal>
						</b-input>
					</b-field>

					<div class="field is-grouped is-grouped-right">
						<p class="control">
							<button class="button is-primary is-large has-text-dark is-pulled-right"
								:class="{'is-loading': isLoading}">
								Login
							</button>
						</p>
					</div>
				</form>
			</section>
		</div>
	</div>
</section>
</template>

<script>

import {
	auth
} from '@/firebase'

export default {
	data: function () {
		return {
			form: {
				email: '',
				password: ''
			},
			isLoading: false,
			error: {
				message: null
			}
		}
	},
	methods: {
		handleLogin: function (evt) {
			evt.preventDefault()

			auth.signInWithEmailAndPassword(this.form.email, this.form.password).then(res => {
				return res.user.getIdTokenResult(true)
			})
			.then(tokenResult => {
				if (!tokenResult.claims.teacher)
				{
					auth.signOut()
					return Promise.reject('invalid credentials')
				}

				// send to dashboard
				this.$router.push('/dashboard')
			})
			.catch(err => {
				console.error(err)
				this.error.message = err
			})
		}
	}
}
</script>

<style scoped>

</style>