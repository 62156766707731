var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentAppointment
    ? _c("div", [
        _vm.classData
          ? _c("div", { staticClass: "box" }, [
              _c("h2", { staticClass: "title is-5" }, [
                _vm._v(" " + _vm._s(_vm.classTitle(_vm.classData)) + " "),
                _c("p", { staticClass: "subtitle" }, [
                  _vm._v(
                    " with " +
                      _vm._s(_vm.classData.instructor.name) +
                      " on " +
                      _vm._s(_vm.classData.dayOfWeek) +
                      "s "
                  )
                ])
              ]),
              _vm._v(" at " + _vm._s(_vm.classData.studio.name) + " ")
            ])
          : _vm._e(),
        _c("div", { staticClass: "box" }, [
          _vm.currentAppointment.dancersScheduled.length > 0
            ? _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                _c("strong", [
                  _vm._v(
                    "Attending (" +
                      _vm._s(_vm.currentAppointment.dancersScheduled.length) +
                      ")"
                  )
                ])
              ])
            : _vm._e(),
          _vm.currentAppointment.dancersScheduled.length
            ? _c("table", { staticClass: "table is-fullwidth is-hoverable" }, [
                _c(
                  "tbody",
                  _vm._l(_vm.dancersForCurrentAppointment, function(dancer) {
                    return _c("tr", { key: dancer.uid }, [
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(dancer.firstName) +
                            " " +
                            _vm._s(dancer.lastName) +
                            " "
                        ),
                        _vm.dancerIsMakeup(dancer)
                          ? _c("span", { staticClass: "tag is-dark" }, [
                              _vm._v("Makeup")
                            ])
                          : _vm.dancerIsFreeClass(dancer)
                          ? _c("span", { staticClass: "tag is-info" }, [
                              _vm._v("Free")
                            ])
                          : _vm._e()
                      ]),
                      _c(
                        "td",
                        { staticStyle: { width: "20px" } },
                        [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: _vm.dancerIsFreeClass(dancer)
                                  ? "Remove"
                                  : "Mark Missed"
                              }
                            },
                            [
                              _c("a", {
                                staticClass: "delete is-medium",
                                on: {
                                  click: function($event) {
                                    return _vm.handleDancerMissSelectedAppointment(
                                      dancer
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  }),
                  0
                )
              ])
            : _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [_vm._v(" No dancers for this class. ")]
              )
        ]),
        _vm.currentAppointment.dancersMissing.length > 0
          ? _c("div", { staticClass: "box" }, [
              _c("strong", [_vm._v("Missing")]),
              _c("table", { staticClass: "table is-fullwidth is-hoverable" }, [
                _c(
                  "tbody",
                  _vm._l(_vm.missingDancersForCurrentAppointment, function(
                    dancer
                  ) {
                    return _c("tr", { key: dancer.uid }, [
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(dancer.firstName) +
                            " " +
                            _vm._s(dancer.lastName) +
                            " "
                        )
                      ])
                    ])
                  }),
                  0
                )
              ])
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }