var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "button",
            {
              staticClass: "button is-outlined",
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_vm._m(0), _c("span", [_vm._v("Back")])]
          ),
          _c("br"),
          _c("br"),
          _c("appointment-info-component", {
            attrs: { "class-uid": _vm.classUid, "class-date": _vm.classDate },
            on: {
              "dancer-miss-begin": function($event) {
                return _vm.setLoading(true)
              },
              "dancer-miss-end": function($event) {
                return _vm.setLoading(false)
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-chevron-left" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }