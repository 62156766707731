import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home.vue'
import Dashboard from './views/Dashboard.vue'

import AppointmentInfo from './views/schedule/AppointmentInfo.vue'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home
		},
		{
			path: '/dashboard/:currentDate?',
			name: 'dashboard',
			component: Dashboard
		},

		{
			path: '/appointments/:classUid/on/:classDate',
			name: 'appointment-info',
			component: AppointmentInfo
		}
	]
})

import { auth } from './firebase'

const guards = [
	{
		// redirect all requests to '/' to '/dashboard' when user logged in
		should: (path) => {
			return path === '/' || path === ''
		},
		beforeEach: (to, from, next) => {
			if (!auth.currentUser) {
				next()
				return
			}

			next('/dashboard')
		}
	}
]

router.beforeEach((to, from, next) => {
	for (var i = 0; i < guards.length; ++i) {
		var guard = guards[i]

		if (guard.should(to.path)) {
			guard.beforeEach(to, from, next)
			return
		}
	}

	// call next to continue if no guards were triggered
	next()
})

export default router
