





















import { Component, Vue } from 'vue-property-decorator'
import AppointmentInfoComponent from './components/AppointmentInfoComponent.vue'

import { sharedInstance as loading } from '@/signals/FullScreenLoadingSignal'

import moment from 'moment'

@Component({
	components: {
		AppointmentInfoComponent
	}
})
export default class AppointmentInfo extends Vue
{
	get classUid()
	{
		return this.$route.params.classUid
	}

	get classDate()
	{
		return moment(this.$route.params.classDate).toDate()
	}

	private setLoading(val: boolean)
	{
		loading.fire(val)
	}
}
