const state = {
	currentTeacher: {}
}

const getters = {
	currentTeacher: state => {
		return state.currentTeacher
	}
}

const mutations = {
	setCurrentTeacher: (state, data) => {
		state.currentTeacher = data
	}
}

export default {
	state,
	getters,
	mutations
}