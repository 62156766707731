<template>
<div v-if="currentAppointment">
	<div class="box" v-if="classData">
		<h2 class="title is-5">
			{{ classTitle(classData) }}
			<p class="subtitle">
				with {{ classData.instructor.name }} on {{ classData.dayOfWeek }}s
			</p>
		</h2>
		at {{ classData.studio.name }}
	</div>
	<div class="box">
		<div style="margin-bottom: 10px" v-if="currentAppointment.dancersScheduled.length > 0">
			<strong>Attending ({{ currentAppointment.dancersScheduled.length }})</strong>
		</div>
		<table class="table is-fullwidth is-hoverable" v-if="currentAppointment.dancersScheduled.length">
			<tbody>
				<tr v-for="dancer in dancersForCurrentAppointment" :key="dancer.uid">
					<td>
						{{ dancer.firstName }} {{ dancer.lastName }}&nbsp;
						<span v-if="dancerIsMakeup(dancer)" class="tag is-dark">Makeup</span>
						<span v-else-if="dancerIsFreeClass(dancer)" class="tag is-info">Free</span>
					</td>
					<td style="width: 20px">
						<b-tooltip :label="dancerIsFreeClass(dancer) ? 'Remove' : 'Mark Missed'">
							<a class="delete is-medium" @click="handleDancerMissSelectedAppointment(dancer)"></a>
						</b-tooltip>
					</td>
				</tr>
			</tbody>
		</table>
		<div v-else class="content has-text-grey has-text-centered">
			No dancers for this class.
		</div>
	</div>
	<div class="box" v-if="currentAppointment.dancersMissing.length > 0">
		<strong>Missing</strong>
		<table class="table is-fullwidth is-hoverable">
			<tbody>
				<tr v-for="dancer in missingDancersForCurrentAppointment" :key="dancer.uid">
					<td>
						{{ dancer.firstName }} {{ dancer.lastName }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
</template>

<script>
import { firestore as db, collections } from '@/firebase'

import findIndex from 'lodash/findIndex'
import map from 'lodash/map'
import find from 'lodash/find'
import remove from 'lodash/remove'
import head from 'lodash/head'
import values from 'lodash/values'
import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'

const AppointmentGenerator = require('studio-shared/appointments')

import loader from '@/dataLoader'

import missMakeup from 'studio-shared/makeups/missMakeup'

export default {
	props: {
		classUid: String,
		classDate: Date
	},
	data: function () {
		return {
			classData: null,
			currentAppointment: null
		}
	},
	mounted: function () {
		loader.fetchAndListenForMissedClassesByDate(this.classDate)
		loader.fetchAndListenForMakeupsByDate(this.classDate)
		loader.fetchAndListenForFreeClassesByDate(this.classDate)

		this.getClassData()
	},
	watch: {
		classes: function () {
			this.getClassData()
		},
		classUid: function () {
			this.getClassData()
		},
		classDate: function () {
			loader.fetchAndListenForMissedClassesByDate(this.classDate)
			loader.fetchAndListenForMakeupsByDate(this.classDate)
			loader.fetchAndListenForFreeClassesByDate(this.classDate)
		}
	},
	computed: {
		classes: function () {
			return this.$store.getters.classes
		},

		appointmentGenerator: function () {
			return new AppointmentGenerator({
				getClass: uid => this.$store.getters.class(uid),
				missedClassesForDate: date => this.$store.getters['missedClassesByDate/forDate'](date),
				makeupsForDate: date => this.$store.getters['makeupsByDate/forDate'](date),
				freeClassesForDate: date => this.$store.getters['freeClassesByDate/forDate'](date),

				dancersForClass: uid => this.$store.getters.dancersForClass(uid),
				joinsForClass: uid => this.$store.getters['eventsByClass/joinsForClass'](uid),
				leavesForClass: uid => this.$store.getters['eventsByClass/leavesForClass'](uid)
			})
		},

		dancersForCurrentAppointment: function () {
			const scheduled = this.currentAppointment.dancersScheduled
			const dancers = map(scheduled, uid => {
				const d = this.$store.getters.dancer(uid)
				if (!d)
				{
					return {
						uid: uid,
						firstName: uid,
						lastName: '(Maybe Archived)'
					}
				}

				return d
			})

			return dancers
		},
		missingDancersForCurrentAppointment: function () {
			return map(this.currentAppointment.dancersMissing, uid => {
				return this.$store.getters.dancer(uid)
			})
		}
	},
	methods: {
		handleDancerMissSelectedAppointment: function (dancer) {
			this.$emit('dancer-miss-begin')

			const classData = this.classData

			if (this.dancerIsFreeClass(dancer))
			{
				loader.fetchAndListenForFreeClasses(dancer.uid).then(() => {

					const list = this.$store.getters['freeClasses/forDancer'](dancer.uid)
					const fcData = find(list, fc => classData.uid === fc.classUid)

					if (!fcData)
					{
						console.error('Could not find free class for dancer...')
						return
					}

					const ref = db.collection(collections.FreeClasses).doc(dancer.uid)

					ref.collection('classes').doc(fcData.uid).delete().then(() => {
						// manually remove this immediately; firebase trigger takes a little while to fire
						remove(this.currentAppointment.dancersFreeClass, dancer.uid)
						remove(this.currentAppointment.dancersScheduled, dancer.uid)
						this.$emit('dancer-miss-end')
					})
				})

				return
			}

			const missed = {
				credits: classData.classType.credits,
				creditsUsed: 0,
				missedClass: classData.uid,
				missedDate: this.currentAppointment.date,
				source: 'teacher'
			}

			if (!this.dancerIsMakeup(dancer))
			{
				db.collection(collections.MissedClasses).doc(dancer.uid).collection('missed').add(missed).then(() => {
					const appt = clone(this.currentAppointment)
					remove(appt.dancersScheduled, uid => uid === dancer.uid)
					appt.dancersMissing.push(dancer.uid)

					this.currentAppointment = appt

					this.$emit('dancer-miss-end')
				})
				.catch(err => {
					console.error(err)
					this.$emit('dancer-miss-end')
				})

				return
			}

			loader.fetchAndListenForMakeups(dancer.uid).then(() => {
				const list = this.$store.getters.makeupsForDancer(dancer.uid)
				
				const makeupData = find(list, mu => {
					const makeupClassUid = head(values(mu.makeupClass))
					return classData.uid === makeupClassUid
				})

				missMakeup(makeupData, missed, dancer.uid).then(() => {
					this.$store.commit('removeMakeup', {
						dancerId: dancer.uid,
						makeup: makeupData
					})

					let idx = this.currentAppointment.dancersMakeup.findIndex(uid => uid === dancer.uid)
					if (idx >= 0)
					{
						this.$delete(this.currentAppointment.dancersMakeup, idx)
					}
					idx = this.currentAppointment.dancersScheduled.findIndex(uid => uid === dancer.uid)
					if (idx >= 0)
					{
						this.$delete(this.currentAppointment.dancersScheduled, idx)
					}

					this.currentAppointment.dancersMissing.push(dancer.uid)
					this.currentAppointment = clone(this.currentAppointment)

					this.$emit('dancer-miss-end')
				})
			})
		},

		dancerIsMakeup: function (dancer) {
			return findIndex(this.currentAppointment.dancersMakeup, uid => uid === dancer.uid) >= 0
		},
		dancerIsFreeClass: function (dancer) {
			return findIndex(this.currentAppointment.dancersFreeClass, uid => uid === dancer.uid) >= 0
		},

		getClassData: function () {
			const data =  this.$store.getters.class(this.classUid)

			if (!data)
			{
				return null
			}

			const cd = clone(data)
			cd.classType = this.$store.getters.classType(cd.classType)
			cd.classLevel = this.$store.getters.classLevel(cd.classLevel)
			cd.instructor = this.$store.getters.teacher(cd.instructor)
			cd.studio = this.$store.getters.studio(cd.studio)

			this.classData = cd

			loader.fetchAndListenForDancerEventsByClass(this.classUid)

			this.currentAppointment = cloneDeep(this.appointmentGenerator.generateClassDataForDate(this.classData, this.classDate))
		},

		classTitle: function (classData) {
			if (!classData)
			{
				return '--'
			}

			const type = classData.classType.name
			const level = type === 'Stretch' ? '' : classData.classLevel.name
			return `${type} ${level} @ ${classData.startTime.replace(' ', '')}`
		}
	}
}
</script>

<style scoped>

</style>