













import { Component, Vue } from 'vue-property-decorator'
import Navigation from '@/components/Navigation.vue'

import { sharedInstance as loading } from '@/signals/FullScreenLoadingSignal'

import { 
	auth, 
	firestore as db,
	collections 
} from './firebase'

@Component({
	components: {
		Navigation
	}
})
export default class App extends Vue
{
	private isLoggedIn = false
	private isLoading = false

	mounted()
	{
		loading.asObservable().subscribe(active => {
			this.isLoading = active
		})

		auth.onAuthStateChanged(user => {
			if (!user) 
			{
				// logged out
				this.isLoggedIn = false
				this.$router.push('/')
				return Promise.resolve()
			}

			user.getIdTokenResult().then(result => {
				if (result.claims.teacher) 
				{
					if (this.$router.currentRoute.path === '/')
					{
						this.$router.push('/dashboard')
					}

					return db.collection(collections.Teachers).doc(user.uid).get().then((snap: firebase.firestore.DocumentSnapshot) => {
						const d = snap.data()!
						d.uid = snap.id

						this.$store.commit('setCurrentTeacher', d)

						this.isLoggedIn = true
					})
				}

				auth.signOut()

				this.isLoggedIn = false
			})
		}, err => {
			console.error(err)
		})
	}
}

