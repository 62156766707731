import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Buefy from 'buefy'
import VeeValidate from 'vee-validate'
import 'vuex'

Vue.use(Buefy, {
	defaultIconPack: 'fas'
})

Vue.use(VeeValidate, {
	fieldsBagName: 'veeFields'
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import loader from './dataLoader'

loader.fetchAndListenForClasses()
loader.fetchAndListenForDancersToClasses()
loader.fetchAndListenForDancers()

loader.once('classes-loaded').then(() => {
	const classes: IStudioClass[] = store.getters.classes
	classes.forEach(classData => {
		loader.fetchAndListenForSubstitutes(classData.uid)
	})
})
